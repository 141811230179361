<template>
  <div>
    <p style="font-size: 50px">🚧 ️⛏️ 🚧</p>
    <p>Vi är inte riktigt klara med den här sidan än!</p>
    <p>
      path: {{ route.path }}<br />
      name: {{ route.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NotImplementedPage",
  metaInfo() {
    return {
      title: this.route.name
    };
  },
  computed: {
    route() {
      return this.$route;
    }
  }
};
</script>
